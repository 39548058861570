document.addEventListener("DOMContentLoaded", function(){
  AOS.init();

  $('nav#menu').mmenu({
    drag: true,
    navbar: {
      title: "選單"
    },
    pageScroll: {
      scroll: true,
      update: true
    },
    'extensions': [
      'theme-dark',
      'fx-menu-fade',
      'fx-listitems-slide'
    ]
  }, {
    classNames: {
      fixedElements: {
        fixed: 'fix',
        sticky: 'is-sticky'
      }
    }
  })
  $('#pageHeader').sticky({topSpacing: 0})
  $('#pageHeader').on('sticky-end', function () { $(this).parent('.sticky-wrapper').css({height: 'auto'}) }) // sticky 補丁

  $('.hero-banner').slick({
    infinite: false,
    cssEase: 'linear',
    dots: true,
    autoplay: true,
    autoplaySpeed: 2800,
    easing: 'easeOutElastic',
  });

  $('.categories-slick').slick({
    infinite: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    dots: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerMode: true,
          variableWidth: true,
          dots: false,
          arrows: false
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  });
  $('.categories-slider').slick({
    infinite: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    dots: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerMode: true,
          variableWidth: true,
          arrows: false
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  });

  feather.replace()


});

let lastKnownScrollPosition = 0;
let ticking = false;
let topAnchor = document.getElementById('goTop')
topAnchor?.addEventListener("click", ()=>{window.scrollTo({top: 0, behavior: 'smooth'});});

function whenScroll(scrollPos) {
  // 依據捲動位置進行某些操作
  if( scrollPos > 200 && !!topAnchor ) {
    topAnchor.classList.add('show')
  } else {
    topAnchor.classList.remove('show')
  }
}

window.addEventListener('load', AOS.refresh)

document.addEventListener('scroll', function(e) {
  lastKnownScrollPosition = window.scrollY;

  if (!ticking) {
    window.requestAnimationFrame(function() {
      whenScroll(lastKnownScrollPosition);
      ticking = false;
    });

    ticking = true;
  }
});